'use client';
import dynamic from 'next/dynamic';

export const StickyCart = dynamic(() => import('@/components/organisms/StickyCart'), {
	ssr: false
});
export const PreviouslyBrowsedItems = dynamic(
	() => import('@/components/organisms/Dynamics/Functions/PreviouslyBrowsedItems'),
	{
		ssr: false
	}
);

export const Navbar = dynamic(() => import('@/components/templates/layout/Header/Navbar'), {
	ssr: false
});